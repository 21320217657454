<template>
  <div>
    <h2>Edit Team</h2>
    <form @submit.prevent="updateTeam">
      <div>
        <label for="teamName">Team Name:</label>
        <input class="inputs" type="text" v-model="team.team_name" id="teamName" required />
      </div>
      <button class="buttons" type="submit">Update Team</button>
      <button class="buttons" @click="$emit('cancel-edit')">Cancel</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'EditTeam',
  props: {
    teamProp: Object,
  },
  data() {
    return {
      team: this.teamProp,
    };
  },
  methods: {
    async updateTeam() {
      try {
        await axios.put(`${process.env.VUE_APP_SERVER_HOST}/teams/updateTeam/${this.team.team_id}`, this.team);
        this.$emit('team-updated');
      } catch (error) {
        console.error('Error updating team:', error);
      }
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}
div {
  margin-bottom: 10px;
}
label {
  margin-bottom: 5px;
}
</style>