<template>
  <div class="top-bar">
    <img alt="tracer logo" src="../assets/logoBY100x77.png" />
    <h2 class="">{{ paneltype }}</h2>
    <div class="user-info">
      <h2>{{ role }}: <i>{{ user.fname }} {{ user.lname }}</i> </h2>
    </div>
    <div>
      <button class="buttons" @click="logout">Log out</button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TopBar',
  props: {
    role: {
      type: String
    },
    paneltype: {
      type: String,
      required: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    logout() {
      this.$store.commit('setUser', {});
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      // removes the token from the header of all axios requests
      delete axios.defaults.headers.common['Authorization'];
      this.$router.push({ name: 'home' });
    },
/*     editProfile() {
      //this.$router.push({ name: 'editProfile' });
    } */
  }
}
</script>

<style lang="css" scoped>
.top-bar {
  display: flex;
  flex-direction: row;
  /* box-sizing: border-box; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* max-width: 1280px;
  margin: 0 auto; */
  padding: 20px;
  border: 1px solid #656565;
  border-radius: 10px;
  box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;
}

/* .tracer-title {
  align-self: flex-start;
} */

.user-info {
  text-align: center;
  color: #FF8E00;
}

/* .logout-button {
  align-self: flex-end;
} */

@media screen and (max-width: 640px) {
  .top-bar {
    flex-direction: column;
    align-items: start;
  }
}
</style>