<template>
  <nav id="log-in-btns">
    <router-link class="router-buttons" style="margin-right: 10px" to="/forgotpassword">Forgot password</router-link> |
    <router-link class="router-buttons" style="margin-left: 10px" to="/login">Login</router-link>
  </nav>
  <div class="home" style="margin-top: 50px">
    <img alt="tracer logo" src="../assets/logo.png" />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    //HelloWorld,
  },
};
</script>

<style lang="css" scoped>
nav {
  color: #656565;
}

#log-in-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #656565;
  border-radius: 10px;
  box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.home img {
  width: 50%;
  height: auto;
}

@media screen and (max-width: 1280px) {
  .home img {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .home img {
    width: 60%;
  }
}

@media screen and (max-width: 640px) {
  #log-in-btns {
    flex-direction: column;
    /* align-items: start; */
  }

  .home img {
    width: 50%;
  }
}
</style>
