<template>
  <router-view />
</template>

<script>
// @ is an alias to /src, imports go here
export default {
  name: "App",
  created() { },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="css">
/* styles put here become global to the whole App */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #656565;
  color: white
}

/* Styles for the main DIV on the index.html page */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;
}

.buttons {
  font-size: 1rem;
  background-color: #656565;
  border: 1px solid #656565;
  color: yellow;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.buttons:hover {
  color: #FF8E00;
}

.buttons:active {
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

.buttons-short {
  font-size: 1rem;
  background-color: #656565;
  border: 1px solid #656565;
  color: yellow;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.buttons-short:hover {
  color: #FF8E00;
}

.buttons-short:active {
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Checkboxes */
.checkboxes {
  color: white;
  font-size: 1.5rem;
  padding: 12px 20px;
  margin: 8px 10px;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;
}

.checkboxes:focus {
  background-color: #656565;
  border: 1px solid #656565;
}

.checkboxes:checked {
  background-color: #656565;
  color: rgba(255, 255, 255, 1);
  box-shadow: none;
}

/* Datalists */
.datalists {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.datalists:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Error messages */
.error-message {
  color: #FF8E00;
}

.error-message-strong {
  color: red;
}

/* Inputs */
.inputs {
  color: white;
  font-size: 1rem;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;
}

.inputs::placeholder {
  color: #fff;
  opacity: 0.7;
}

.inputs:focus {
  background-color: #656565;
  border: 1px solid #656565;
}

/* Labels */
.labels {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: yellow;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

/* Options */
.options {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.options:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Outputs */
.outputs {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.outputs:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Passwords */
.passwords {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.passwords:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Radios */
.radios {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
  text-align: left;
}

.radios:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

.radios:checked {
  background-color: #656565;
}

/* Router buttons */
.router-buttons {
  font-size: 1rem;
  background-color: #656565;
  border: 1px solid #656565;
  color: yellow;
  /* font-weight: bold; */
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.router-buttons:hover {
  color: #FF8E00;
}

.router-buttons:active {
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Selects */
.selects {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.selects:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Success messages */
.success-message {
  color: #00FF00;
}

/* Textareas */
.textareas {
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

.textareas:focus {
  background-color: #656565;
  border: 1px solid #656565;
  box-shadow: inset 5px 5px 10px #3d3d3d, inset -5px -5px 10px #949494;
}

/* Texts */
.texts {
  color: white;
  font-size: 1rem;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #656565;
  border: 1px solid #656565;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;
}

.texts::placeholder {
  color: #fff;
  opacity: 0.7;
}

.texts:focus {
  background-color: #656565;
  border: 1px solid #656565;
}



/* Responsive design */

/* between 1024px and 1280px these apply */
@media screen and (max-width: 1280px) {
  html {
    font-size: 16px;
  }


}

/* below 1024px these apply */
@media screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }


}

/* below 640px these apply */
@media screen and (max-width: 640px) {
  html {
    font-size: 12px;
  }

}
</style>
