import axios from 'axios';

export default {

  /* validates if a password is in the correct format, if it is it returns true
  The regular expression is used to validate a password based on the following conditions:
  - `(?=.*\d)`: This is a positive lookahead that checks if there is at least one digit in the string.
  - `(?=.*[a-z])`: This is a positive lookahead that checks if there is at least one lowercase letter in the string.
  - `(?=.*[A-Z])`: This is a positive lookahead that checks if there is at least one uppercase letter in the string.
  - `.{6,20}`: This checks if the length of the string is between 6 and 20 characters.
  So, this regular expression checks if a password contains at least one digit, one lowercase letter, one uppercase letter, and is between 6 and 20 characters in length. */
  validatePassword(password) {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    return re.test(password);
  },
  async validateEmailExists(userEmail) {
    const serverHost = process.env.VUE_APP_SERVER_HOST;
    //const serverPort = process.env.VUE_APP_SERVER_PORT;
    const url = `${serverHost}/auth/emailexists`;
    const response = await axios.post(url, { email: userEmail });
    return response.data.emailExists;
  },
  /* validates if an email is in the correct format, if it is it returns true */
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  /* validate if a the passed argument is a 4 digit numeric string, retrun false if it is not */
  validatePIN(pin) {
    const re = /^\d{4}$/;
    return re.test(pin);
  },


};


