<template>
  <div class="grid-container">
    <top-bar role="Trainee" paneltype="Trainee Panel" class="t-bar" />
    <div class="toolbar">      
      <button class="toolbar-button buttons">My training history</button>
    </div>
    <div class="content">

      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";

export default {
  name: "TraineePanelView",
  components: {
    TopBar,
  },
  data() {
    return {
      message: "At the moment there is no functionality for trainees and therefore they do not even need to log in to this dashboard",
    };
  },
};
</script>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 5fr;
  grid-gap: 10px;
  height: 100vh;
}

.t-bar {
  grid-column: span 2;
}

.toolbar {
  display: flex;
  flex-direction: column;
  /* padding-right: 20px; */
}

.toolbar-button {
  width: 100%;
  margin-top: 20px;
}

.content {
  padding: 20px;
  /* box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;   */
}

@media (max-width: 640px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
