<template>
  <div>
    <h2>Edit Team</h2>
    <form @submit.prevent="updateTeam">
      <div>
        <label for="teamName">Team Name:</label>
        <input type="text" class="texts" v-model="localTeam.team_name" id="teamName" required />
      </div>
      <button type="submit" style="margin-bottom: 20px;" class="buttons">Update Team</button>
      <button class="buttons" @click="$emit('cancel-team')">Cancel</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OrgEditTeam',
  props: {
    team: Object,
  },
  data() {
    return {
      localTeam: this.team,
    };
  },
  methods: {
    async updateTeam() {
      try {
        await axios.put(`${process.env.VUE_APP_SERVER_HOST}/teams/updateTeam/${this.localTeam.team_id}`, {
          org_id: this.localTeam.org_id,
          team_name: this.localTeam.team_name,
        });
        this.$emit('team-updated');
      } catch (error) {
        console.error('Error updating team:', error);
      }
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}
div {
  margin-bottom: 10px;
}
label {
  margin-bottom: 5px;
}
/* input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
} */
</style>