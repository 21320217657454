<template>
  <div class="grid-container">
    <div class="topbar">
      <top-bar role="Admin" paneltype="Admin Panel" />
    </div>
    <div class="sidebar">
      <button class="toolbar-button buttons" @click="currentComponent = 'AddOrg'">Add Org</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'AllOrgs'">Organizations</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'AddUser'">Add User</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'EditUser'">Edit User</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'AllUsers'">Users</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'CreateTeam'">Create Teams</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'AllTeams'">Teams</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'Dashboards'">Dashboards</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'EditProfile'">Edit My Profile</button>
    </div>
    <div class="main">
      <component :is="currentComponent" @edit-org="editOrg" :org="selectedOrg" @org-updated="fetchOrgs" @edit-team="editTeam" @team-updated="fetchTeams" :teamProp="selectedTeam" @cancel-edit="cancelEdit"></component>
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import AddOrg from "../components/AddOrg.vue";
import AddUser from "../components/AddUser.vue";
import EditUser from "../components/EditUser.vue";
import EditProfile from "../components/EditProfile.vue";
import AllOrgs from "../components/AllOrgs.vue";
import EditOrg from "../components/EditOrg.vue";
import AllUsers from "../components/AllUsers.vue";
import CreateTeam from "../components/CreateTeam.vue";
import AllTeams from '../components/AllTeams.vue';
import EditTeam from '../components/EditTeam.vue';

export default {
  name: "AdminPanelView",
  components: {
    TopBar,
    AddOrg,
    AddUser,
    EditUser,
    EditProfile,
    AllOrgs,
    EditOrg,
    AllUsers,
    CreateTeam,
    AllTeams,
    EditTeam,
  },
  data() {
    return {
      currentComponent: null,
      selectedOrg: null,
      selectedTeam: null,
    };
  },
  methods: {
    editOrg(org) {
      this.selectedOrg = org;
      this.currentComponent = 'EditOrg';
    },
    fetchOrgs() {
      this.currentComponent = 'AllOrgs';
    },
    editTeam(team) {
      this.selectedTeam = team;
      this.currentComponent = EditTeam;
    },
    fetchTeams() {
      this.currentComponent = AllTeams;
    },
    cancelEdit() {
      this.currentComponent = AllTeams;
    },
  },
};
</script>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-areas:
    "topbar1 topbar1 topbar1 topbar1 topbar1 topbar1"
    "sidebar1 main1 main1 main1 main1 main1"
    "sidebar1 main1 main1 main1 main1 main1"
    "sidebar1 main1 main1 main1 main1 main1"
    "sidebar1 main1 main1 main1 main1 main1";
  grid-gap: 10px;

}

.topbar {
  grid-area: topbar1;

}

.sidebar {
  grid-area: sidebar1;

}

.main {
  grid-area: main1;
  padding: 0px 20px 20px 20px;
  /* box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494; */
}

.toolbar-button {
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 640px) {
  .grid-container {
    display: grid;
    grid-template-areas:
      "topbar1"
      "sidebar1"
      "main1";
    grid-gap: 1px;
    /* grid-template-columns: 1fr; */
  }
}

</style>
