import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
//import NotFoundView from '@/views/NotFoundView.vue' 
//import AboutView from '@/views/AboutView.vue' 
import ForgotPasswordView from '@/views/ForgotPasswordView.vue'
import AdminPanelView from '@/views/AdminPanelView.vue'
import TrainerPanelView from '@/views/TrainerPanelView.vue'
import TraineePanelView from '@/views/TraineePanelView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/adminpanel',
    name: 'adminpanel',
    component: AdminPanelView
  },
  {
    path: '/trainerpanel',
    name: 'trainerpanel',
    component: TrainerPanelView
  },
  {
    path: '/traineepanel',
    name: 'traineepanel',
    component: TraineePanelView
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPasswordView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/NotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// navigation guard, will not navigate to the route if the user is not authenticated, except for the home, login and forgotpassword pages
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token'); // if token exists, isAuthenticated becomes true
  // Allow navigation if the route is either 'login' 'home' 'forgotpassword' or if the user is authenticated
  if (to.name === 'login' || to.name === 'home' || to.name === 'forgotpassword' || isAuthenticated) next();
  else next({ name: 'login' }); // else Redirect to login if not authenticated and trying to access other routes
});

export default router;
