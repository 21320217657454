<template>
  <div>
    <h2>All Teams</h2>
    <table>
      <thead>
        <tr>
          <th @click="sort('team_id')">Team ID</th>
          <th @click="sort('org_id')">Org ID</th>
          <th @click="sort('team_name')">Team Name</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="team in sortedTeams" :key="team.team_id">
          <td>{{ team.team_id }}</td>
          <td>{{ team.org_id }}</td>
          <td>{{ team.team_name }}</td>
          <td><button class="buttons-short" @click="editTeam(team)">Edit</button></td>
          <td><button class="buttons-short" @click="deleteTeam(team.team_id)">Delete</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AllTeams',
  data() {
    return {
      teams: [],
      sortKey: '',
      sortAsc: true,
    };
  },
  computed: {
    sortedTeams() {
      return [...this.teams].sort((a, b) => {
        let result = 0;
        if (a[this.sortKey] < b[this.sortKey]) result = -1;
        if (a[this.sortKey] > b[this.sortKey]) result = 1;
        return this.sortAsc ? result : -result;
      });
    },
  },
  methods: {
    async fetchTeams() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_HOST}/teams/getAllTeams`);
        this.teams = response.data;
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
    async deleteTeam(teamId) {
      try {
        await axios.delete(`${process.env.VUE_APP_SERVER_HOST}/teams/deleteTeam/${teamId}`);
        this.teams = this.teams.filter(team => team.team_id !== teamId);
      } catch (error) {
        console.error('Error deleting team:', error);
      }
    },
    editTeam(team) {
      this.$emit('edit-team', team);
    },
  },
  created() {
    this.fetchTeams();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #656565;
  cursor: pointer;
}

button {
  margin: 5px;
}
</style>