<template>
  <div>
    <h2>Trainees</h2>
    <table>
      <thead>
        <tr>
          <th style="cursor: pointer" @click="sort('user_id')">User ID</th>
          <th style="cursor: pointer" @click="sort('fname')">First Name</th>
          <th style="cursor: pointer" @click="sort('lname')">Last Name</th>
          <th style="cursor: pointer" @click="sort('email')">Email</th>
          <th>Edit</th>
          <th>Delete</th>
          <th>Dashboard</th>
          <th>History</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in sortedUsers" :key="user.user_id">
          <td>{{ user.user_id }}</td>
          <td>{{ user.fname }}</td>
          <td>{{ user.lname }}</td>
          <td>{{ user.email }}</td>
          <td><button class="buttons-short" @click="editTrainee(user)">Edit</button></td>
          <td><button class="buttons-short" @click="deleteTrainee(user.user_id)">Delete</button></td>
          <td><button class="buttons-short">Dashboard</button></td>
          <td><button class="buttons-short">History</button></td>
        </tr>
      </tbody>
    </table>
    <OrgEditTrainee v-if="showEditUserForm" :user="editUserData" @close="showEditUserForm = false" />
  </div>
</template>

<script>
import axios from 'axios';
import OrgEditTrainee from './OrgEditTrainee.vue';

export default {
  name: 'OrgAllTrainees',
  components: {
    OrgEditTrainee,
  },
  data() {
    return {
      users: [],
      showEditUserForm: false,
      editUserData: {},
      sortKey: '',
      sortAsc: true,
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.getUser.orgId;
    },
    sortedUsers() {
      return [...this.users].sort((a, b) => {
        let result = 0;
        if (a[this.sortKey] < b[this.sortKey]) result = -1;
        if (a[this.sortKey] > b[this.sortKey]) result = 1;
        return this.sortAsc ? result : -result;
      });
    },
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_HOST}/users/getall`);
        this.users = response.data.filter(user => user.org_id === this.orgId);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    editTrainee(user) {
      // this.editUserData = { ...user };
      // this.showEditUserForm = true;
      this.$emit('edit-trainee', user);
    },
    // async updateUser() {
    //   try {
    //     await axios.post(`${process.env.VUE_APP_SERVER_HOST}:${process.env.VUE_APP_SERVER_PORT}/users/updateUser/${this.editUserData.id}`, this.editUserData);
    //     const user = this.users.find(u => u.id === this.editUserData.id);
    //     Object.assign(user, this.editUserData);
    //     this.showEditUserForm = false;
    //   } catch (error) {
    //     console.error('Error updating user:', error);
    //   }
    // },
    async deleteTrainee(userId) {
      try {
        await axios.delete(`${process.env.VUE_APP_SERVER_HOST}/users/deleteUser/${userId}`);
        this.users = this.users.filter(user => user.user_id !== userId);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
}
th {
  background-color: #656565;
}
button {
  margin: 5px;
}
</style>