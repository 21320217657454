<template>
  <div class="forgot-password-view">
    <nav style="margin-bottom: 30px" id="log-in-btns">
      <router-link class="router-buttons" style="margin-left: 10px" to="/login">Login</router-link>
    </nav>
    <PasswordResetRequest />
    <div style="height: 30px;"></div>
    <PasswordChange />
  </div>
</template>

<script>
import PasswordResetRequest from "@/components/PasswordResetRequest.vue";
import PasswordChange from "@/components/PasswordChange.vue";

export default {
  name: "ForgotPasswordView",
  created() { },
  components: {
    PasswordResetRequest,
    PasswordChange,
  },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="css" scoped>
nav {
  color: #656565;
}

#log-in-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #656565;
  border-radius: 10px;
  box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;
}

.home img {
  width: 90%;
  height: auto;
}

@media screen and (max-width: 1280px) {
  .home img {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .home img {
    width: 60%;
  }
}

@media screen and (max-width: 640px) {
  #log-in-btns {
    flex-direction: column;
    /* align-items: start; */
  }

  .home img {
    width: 50%;
  }
}
</style>