<template>
  <div>
    <div class="form-container" style="margin-top: 30px">
      <!-- <h2 style="color:#FFF; margin-top: 0px">Σύνδεση</h2> -->
      <h2 style="color:#FFF; margin-top: 0px">Log-in form</h2>
      <!-- <p style="color: white">Συμπληρώστε τα στοιχεία σας για να συνδεθείτε.</p> -->
      <p style="color: white">Enter your details to log-in.</p>
      <hr style="margin-bottom: 15px">
      <form @submit.prevent="login">
        <div>
          <label class="labels" for="email">E-mail:</label>
          <input class="inputs" type="email" id="email" v-model="email" @input="validateEmail" @click="hideErrorMsgs"
            placeholder="Enter your email address">
          <!-- <p v-if="emailError" style="color: #FF8E00;">Μη έγκυρη διεύθυνση e-mail</p> -->
          <p v-if="emailError" style="color: #FF8E00;">Not a valid e-mail address</p>
        </div>
        <div>
          <!-- <label class="labels" for="password">Κωδικός Πρόσβασης:</label> -->
          <label class="labels" for="password">Password:</label>
          <input class="inputs" type="password" id="password" v-model="password" @input="validatePassword"
            @click="hideErrorMsgs" placeholder="Enter your password">
          <!-- <p v-if="passwordError" style="color: #FF8E00;">Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6
            χαρακτήρες</p> -->
          <p v-if="passwordError" style="color: #FF8E00;">The password must have at least one digit, one lowercase
            letter, one uppercase letter, and is between 6 and 20 characters long.</p>
        </div>
        <!-- <p v-if="passwordWrong" class="error-message">Λάθος κωδικός πρόσβασης δοκιμάστε ξανά.</p>
        <p v-if="noSuchUser" class="error-message">Δεν υπάρχει χρήστης με αυτό το e-mail.</p> -->
        <p v-if="passwordWrong" class="error-message">Wrong password please try again.</p>
        <p v-if="noSuchUser" class="error-message">No user with this e-mail address.</p>

        <!-- <button class="buttons" style="margin-top: 30px;" type="submit">Σύνδεση</button> -->
        <button class="buttons" style="margin-top: 30px;" type="submit">Submit</button>
      </form>

      <div style="padding: 20px">
        <router-link class="" style="color: white" to="/forgotpassword">I forgot my password</router-link>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';
import utils from '../utilities/utils';

export default {
  name: "LoginComp",
  data() {
    return {
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      passwordWrong: false,
      noSuchUser: false
    };
  },
  methods: {
    async login() {
      this.emailError = !utils.validateEmail(this.email);
      //todo when the app finishes uncomment the line below and makethe if statement as below
      //this.passwordError = !utils.validatePassword(this.password);
      // if (utils.validateEmail(this.email) && utils.validatePassword(this.password)) {

      if (utils.validateEmail(this.email)) {
        try {
          const serverHost = process.env.VUE_APP_SERVER_HOST;
          // const serverPort = process.env.VUE_APP_SERVER_PORT;
          const url = `${serverHost}/auth/login`;
          const response = await axios.post(url, {
            email: this.email.toLowerCase(),
            password: this.password
          });

          // if there was no error and the response was successful
          // console.log('TRACER: The response from the backend is: ', response.data);
          this.$store.commit('setUser', response.data);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', response.data.userId.toString());
          // adds the token to the header of all axios requests
          axios.defaults.headers.common['Authorization'] = response.data.token;

          if (response.data.admin === 1) {
            router.push({ name: 'adminpanel' });
          } else if (response.data.trainer === 1) {
            router.push({ name: 'trainerpanel' });
          } else {
            router.push({ name: 'traineepanel' });
          }

          //Axios will throw an error if the status code is between 400 and 599
        } catch (error) {
          //
          if (error.response.status === 401) {
            if (error.response.data.data === 'no_email') {
              this.noSuchUser = true;
            } else if (error.response.data.data === 'wrong_password') {
              this.passwordWrong = true;
            }
          } else {
            console.error('TRACER: The error from the backend is: ', error);
          }
        }
      }
    },
    hideErrorMsgs() {
      this.emailError = false;
      this.passwordError = false;
      this.passwordWrong = false;
      this.noSuchUser = false;
    },
    validateEmail() {
      this.emailError = !utils.validateEmail(this.email);
    },

    /*    TODO when the app finishes uncomment the validatePassword method 
    validatePassword() {
          this.passwordError = !utils.validatePassword(this.password);
        } */
  }
};
</script>

<style scoped>
.form-container {
  border: 1px solid #656565;
  border-radius: 10px;
  max-width: 540px;
  min-width: 400px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}
</style>