<template>
  <div>
    <h2>Edit Trainee</h2>
    <form @submit.prevent="updateTrainee">
      <div class="grid-container">
        <div class="form-title"></div>
        <!-- First and last name -->
        <div class="names">
          <label class="labels" for="title">Title:</label>
          <select class="selects" id="title" v-model="user.title" style="margin-right: 20px;" :disabled="formDisabled">
            <option>Mr</option>
            <option>Mrs</option>
            <option>Ms</option>
            <option>Dr</option>
            <option>Prof</option>
          </select>

          <!-- <label class="labels" for="fname">First Name:</label> -->
          <input class="texts" type="text" v-model="user.fname" id="fname" name="fname" placeholder="First name"
            style="margin-right: 20px;" :disabled="formDisabled">

          <!-- <label class="labels" for="lname">Last Name: </label> -->
          <input class="texts" type="text" v-model="user.lname" id="lname" name="lname" placeholder="Last name"
            :disabled="formDisabled">
        </div>

        <div class="team-rank">
          <!-- Organization -->
          <div style="display: flex; align-items: center; margin-right: 20px">
            <label class="labels" for="team">Team:</label>
            <select class="selects" id="team" v-model="user.team_id" :disabled="formDisabled">
              <option disabled value=0>Select</option>
              <option v-for="team in teams" :value="team.team_id" :key="team.team_id">{{ team.team_name }}</option>
            </select>
          </div>

          <!-- Rank -->
          <div style="display: flex; align-items: center; margin-right: 20px">
            <label class="labels" for="rank">Rank:</label>
            <input class="texts" type="text" v-model="user.rank" id="rank" name="rank" placeholder="Rank"
              :disabled="formDisabled">
          </div>

          <!-- Admin, Trainer, Trainee -->
          <div style="display: flex; align-items: center;">
            <!-- <label class="labels" for="admin">Admin:
              <input class="checkboxes" type="checkbox" v-model="user.admin" id="admin" name="admin"
                :disabled="formDisabled">
            </label> -->

            <label class="labels" for="trainer">Trainer:
              <input class="checkboxes" type="checkbox" v-model="user.trainer" id="trainer" name="trainer"
                :disabled="formDisabled">
            </label>

            <label class="labels" for="trainee">Trainee:
              <input class="checkboxes" type="checkbox" v-model="user.trainee" id="trainee" name="trainee"
                :disabled="formDisabled">
            </label>
          </div>
        </div>

        <!-- e-mail -->
        <div class="email"> <!-- <label class="labels" for="email">E-mail address:</label> -->
          <input class="texts" id="email" type="email" v-model="user.email" @input="checkEmailFormat"
            @blur="checkEmailExistance" placeholder="E-mail address">
        </div>

        <div class="address"> <!-- <label class="labels" for="address">Address:</label> -->
          <input class="texts" type="text" v-model="user.address" id="address" name="address"
            placeholder="Street address" :disabled="formDisabled">
        </div>

        <div class="city"> <!-- <label class="labels" for="city">City:</label> -->
          <input class="texts" type="text" v-model="user.city" id="city" name="city" placeholder="City"
            :disabled="formDisabled">
        </div>

        <div class="state"> <!-- <label class="labels" for="state">State:</label> -->
          <input class="texts" type="text" v-model="user.state" id="state" name="state" placeholder="State"
            :disabled="formDisabled">
        </div>

        <div class="postal-code"> <!-- <label class="labels" for="postal_code">Postal Code:</label> -->
          <input class="texts" type="text" v-model="user.postal_code" id="postal_code" name="postal_code"
            placeholder="Postal code" :disabled="formDisabled">
        </div>

        <div class="country">
          <!-- TODO for the country I should create a global object with all countries and use it to create a drop down -->
          <!-- <label class="labels" for="country">Country:</label> -->
          <input class="texts" type="text" v-model="user.country" id="country" name="country" placeholder="Country"
            :disabled="formDisabled">
        </div>

        <div class="phone"> <!-- <label class="labels" for="phone">Phone:</label> -->
          <input class="texts" type="tel" v-model="user.phone" id="phone" name="phone" placeholder="Phone"
            :disabled="formDisabled">
        </div>

        <div class="mobile"> <!-- <label class="labels" for="mobile">Mobile:</label> -->
          <input class="texts" type="tel" v-model="user.mobile" id="mobile" name="mobile" placeholder="Mobile"
            :disabled="formDisabled">
        </div>

        <div class="password"> <!-- <label class="labels" for="password">Initial password:</label> -->
          <input class="texts" type="password" v-model="user.password" id="password" name="password"
            placeholder="Type a new password" @input="checkPasswordFormat">
        </div>

        <div class="pin-active">
          <!-- PIN -->
          <input class="texts" type="text" v-model="user.PIN" id="PIN" name="PIN" placeholder="Add 4 digit numeric PIN"
            v-if="user.trainee" style="margin-right: 20px" :disabled="formDisabled">

          <!-- Active  -->
          <label class="labels" for="active">Active:
            <input type="checkbox" class="checkboxes" id="active" v-model="user.active" :disabled="formDisabled">
          </label>
        </div>

        <div class="notes">
          <!-- <label class="labels" for="notes">Notes:</label> -->
          <textarea class="texts" v-model="user.notes" id="notes" name="notes" placeholder="Notes"
            :disabled="formDisabled"></textarea>
        </div>

      </div>
      <div class="form-footer">
        <button type="button" class="buttons" @click="$emit('show-trainees')">Cancel</button>
        <div>
          <p v-show="userUpdatedSuccessfully" class="success-message"><strong>User updated successfully</strong></p>
          <p v-show="userUpdateError" class="error-message-strong"><strong>There was an error, the user was not
              updated</strong></p>
          <p v-if="emailExists" class="error-message">This e-mail already exists. If you are keeping the same e-mail
            address, please ignore this message. Otherwise, please provide a different e-mail address.</p>
          <p v-if="emailError" class="error-message">The e-mail address you provided is not in the correct format</p>
          <p class="error-message" v-if="passwordError">The password must have at least one digit, one lowercase
            letter, one uppercase letter, and is between 6 and 20 characters long.</p>
          <p class="error-message" v-if="PINError">The PIN must be a 4 digit numeric string</p>
        </div>
        <button type="submit" class="buttons">Update</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import utils from '../utilities/utils';

export default {
  name: 'OrgEditTrainee',
  props: {
    trainee: Object,
  },
  data() {
    return {
      user: this.convertTrainee(this.trainee),
      teams: [],
      formDisabled: false,
      emailError: false,
      passwordError: false,
      emailExists: false,
      PINError: false,
      userUpdatedSuccessfully: false,
      userUpdateError: false,

    };
  },
  computed: {
    orgId() {
      return this.$store.getters.getUser.orgId;
    },
  },
  async created() {
    await this.fetchTeams();
  },
  methods: {
    async updateTrainee() {
      if (this.emailError || this.passwordError || this.PINError) {
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_SERVER_HOST}/users/updateUser/${this.user.user_id}`, this.user);
        this.userUpdatedSuccessfully = true;

        this.$emit('show-trainees');
      } catch (error) {
        this.userUpdateError = true;
        console.error('Error updating trainee:', error);
      }
    },
    convertTrainee(trainee) {
      return {
        ...trainee,
        active: Boolean(trainee.active),
        admin: Boolean(trainee.admin),
        trainer: Boolean(trainee.trainer),
        trainee: Boolean(trainee.trainee),
        password: '',
      };
    },
    async fetchTeams() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_HOST}/teams/getTeamsByOrg/${this.orgId}`);
        this.teams = response.data;
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    },
    checkPasswordFormat() {
      if (this.user.password.length === 0) {
        this.passwordError = false;
        return;
      }
      this.passwordError = !utils.validatePassword(this.user.password);
    },
    checkEmailFormat() {
      this.emailError = !utils.validateEmail(this.user.email);
    },
    // validates if the PIN is a 4 digit numeric string
    checkPIN() {
      this.PINError = !utils.validatePIN(this.user.PIN);
    },
    async checkEmailExistance() {
      this.emailExists = await utils.validateEmailExists(this.user.email);

    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  /* grid-template-columns: 1fr 5fr; */
  grid-template-areas:
    "r1 r1"
    "r2 r2"
    "r3 r3"
    "r4 r4"
    "r5 r5"
    "r6c1 r6c2"
    "r7c1 r7c2"
    "r8c1 r8c2"
    "r9c1 r9c2"
    "r10 r10"
    "r11c1 r11c2";
  grid-gap: 10px;

}

.form-title {
  grid-area: r1;
}

.names {
  grid-area: r2;
  display: flex;
  align-items: center;
  width: 100%;
}

.team-rank {
  grid-area: r3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.email {
  grid-area: r4;
}

.address {
  grid-area: r5;
}

.city {
  grid-area: r6c1;
}

.state {
  grid-area: r6c2;
}

.postal-code {
  grid-area: r7c1;
}

.country {
  grid-area: r7c2;
}

.phone {
  grid-area: r8c1;
}

.mobile {
  grid-area: r8c2;
}

.password {
  grid-area: r9c1;
}

.pin-active {
  grid-area: r9c2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes {
  grid-area: r10;
}

.submit {
  grid-area: r11c2;
  display: flex;
  justify-content: flex-end;
}

/* .names select, */
.names input {
  display: inline-block;
  width: 100%;

}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .grid-container {
    display: grid;
    grid-template-areas:
      "r1"
      "r2"
      "r3"
      "r4"
      "r5"
      "r6c1"
      "r6c2"
      "r7c1"
      "r7c2"
      "r8c1"
      "r8c2"
      "r9c1"
      "r9c2"
      "r10"
      "r11c1"
      "r11c2";
    grid-gap: 1px;

  }

  .names {
    flex-wrap: wrap;
  }

  .names label,
  .names select,
  .names input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>