<template>
  <div>
    <h2>Create New Team</h2>
    <form @submit.prevent="createTeam">
      <div>
        <label for="teamName">Team Name:</label>
        <input type="text" class="texts" v-model="teamName" id="teamName" required />
      </div>
      <button class="buttons" type="submit">Create Team</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OrgCreateTeam',
  data() {
    return {
      teamName: '',
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.getUser.orgId;
    },
  },
  methods: {
    async createTeam() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_SERVER_HOST}/teams/addTeam`, {
          org_id: this.orgId,
          team_name: this.teamName,
        });
        alert('Team created successfully', response);
        this.teamName = '';
      } catch (error) {
        console.error('Error creating team:', error);
      }
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

div {
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
}

/* input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
} */

/* button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
} */
</style>