<template>
  <div>
    <form v-if="user" @submit.prevent="updateProfile">
      <div class="grid-container">
        <div class="form-title">
          <h2>Edit Profile</h2>
        </div>
        <!-- First and last name -->
        <div class="names">
          <label class="labels" for="title">Title:</label>
          <select class="selects" id="title" v-model="user.title" style="margin-right: 20px;">
            <option disabled value="">Select</option>
            <option>Mr</option>
            <option>Mrs</option>
            <option>Ms</option>
            <option>Dr</option>
            <option>Prof</option>
          </select>

          <!-- <label class="labels" for="fname">First Name:</label> -->
          <input class="texts" type="text" v-model="user.fname" id="fname" name="fname" placeholder="First name"
            style="margin-right: 20px;">

          <!-- <label class="labels" for="lname">Last Name: </label> -->
          <input class="texts" type="text" v-model="user.lname" id="lname" name="lname" placeholder="Last name">
        </div>

        <div class="org-rank">
          <!-- Organization -->
          <div style="display: flex; align-items: center; margin-right: 20px">
            <label class="labels" for="org">Organization:</label>
            <select class="selects" id="org" v-model="user.org_id">
              <option disabled value="">Select</option>
              <option v-for="org in orgs" :value="org.ORG_ID" :key="org.ORG_ID">{{ org.NAME }}</option>
            </select>
          </div>

          <!-- Rank -->
          <div style="display: flex; align-items: center; margin-right: 20px">
            <label class="labels" for="rank">Rank:</label>
            <input class="texts" type="text" v-model="user.rank" id="rank" name="rank" placeholder="Rank">
          </div>

          <!-- Admin, Trainer, Trainee -->
          <div style="display: flex; align-items: center;">
            <label class="labels" for="admin">Admin:
              <input class="checkboxes" type="checkbox" v-model="user.admin" id="admin" name="admin">
            </label>

            <label class="labels" for="trainer">Trainer:
              <input class="checkboxes" type="checkbox" v-model="user.trainer" id="trainer" name="trainer">
            </label>

            <label class="labels" for="trainee">Trainee:
              <input class="checkboxes" type="checkbox" v-model="user.trainee" id="trainee" name="trainee">
            </label>
          </div>
        </div>

        <!-- e-mail -->
        <div class="email"> <!-- <label class="labels" for="email">E-mail address:</label> -->
          <input class="texts" id="email" type="email" v-model="user.email" @input="checkEmailFormat"
            @blur="checkEmailExistance" placeholder="E-mail address">
          <p v-if="emailExists" class="error-message">This e-mail already exists. If you are keeping the same e-mail
            address, please ignore this message. Otherwise, please provide a different e-mail address.
          </p>
          <p v-if="emailError" class="error-message">The e-mail address you provided is not in the correct format</p>
        </div>

        <div class="address"> <!-- <label class="labels" for="address">Address:</label> -->
          <input class="texts" type="text" v-model="user.address" id="address" name="address"
            placeholder="Street address">
        </div>

        <div class="city"> <!-- <label class="labels" for="city">City:</label> -->
          <input class="texts" type="text" v-model="user.city" id="city" name="city" placeholder="City">
        </div>

        <div class="state"> <!-- <label class="labels" for="state">State:</label> -->
          <input class="texts" type="text" v-model="user.state" id="state" name="state" placeholder="State">
        </div>

        <div class="postal-code"> <!-- <label class="labels" for="postal_code">Postal Code:</label> -->
          <input class="texts" type="text" v-model="user.postal_code" id="postal_code" name="postal_code"
            placeholder="Postal code">
        </div>

        <div class="country">
          <!-- TODO for the country I should create a global object with all countries and use it to create a drop down -->
          <!-- <label class="labels" for="country">Country:</label> -->
          <input class="texts" type="text" v-model="user.country" id="country" name="country" placeholder="Country">
        </div>

        <div class="phone"> <!-- <label class="labels" for="phone">Phone:</label> -->
          <input class="texts" type="tel" v-model="user.phone" id="phone" name="phone" placeholder="Phone">
        </div>

        <div class="mobile"> <!-- <label class="labels" for="mobile">Mobile:</label> -->
          <input class="texts" type="tel" v-model="user.mobile" id="mobile" name="mobile" placeholder="Mobile">
        </div>

        <div class="password"> <!-- <label class="labels" for="password">Initial password:</label> -->
          <input class="texts" type="password" v-model="user.password" id="password" name="password"
            placeholder="Type a new password" @input="checkPasswordFormat">
          <p class="error-message" v-if="passwordError">The password must have at least one digit, one lowercase
            letter, one uppercase letter, and is between 6 and 20 characters long.</p>
        </div>

        <div class="active">
          <!-- Active  -->
          <label class="labels" for="active">Active:
            <input type="checkbox" class="checkboxes" id="active" v-model="user.active">
          </label>
        </div>

        <div class="notes">
          <!-- <label class="labels" for="notes">Notes:</label> -->
          <textarea class="texts" v-model="user.notes" id="notes" name="notes" placeholder="Notes"></textarea>
        </div>

      </div>
      <div class="form-footer">
        <div>
          <p v-show="profileEditSuccess" class="success-message"><strong>Profile updated successfully</strong></p>
          <p v-show="profileEditError" class="error-message-strong"><strong>There was an error, the profile was not
              updated</strong></p>
        </div>

        <button type="submit" class="buttons">Update Profile</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import utils from '../utilities/utils';

export default {
  data() {
    return {
      user: null, // For storing the user's data
      orgs: [], // For storing the list of organizations
      profileEditSuccess: false,
      profileEditError: false,
      emailError: false,
      emailExists: false,
      passwordError: false,

    };
  },
  async created() {
    await this.fetchAllOrgs(); // fetches all organizations to fill the dropdown
    await this.fetchUserProfile(); // fetches the user's data
  },
  methods: {
    async fetchAllOrgs() {
      const serverHost = process.env.VUE_APP_SERVER_HOST;
      // const serverPort = process.env.VUE_APP_SERVER_PORT;
      const url = `${serverHost}/users/getallorgs`;
      try {
        const response = await axios.get(url);
        this.orgs = response.data.orgs;
      } catch (error) {
        console.error("TRACER Error fetching organizations:", error);
      }
    },
    async fetchUserProfile() {
      // gets the user's ID from the localStorage
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("TRACER FRONTEND User ID not found in localStorage");
        return;
      }
      try {
        const serverHost = process.env.VUE_APP_SERVER_HOST;
        // const serverPort = process.env.VUE_APP_SERVER_PORT;
        const url = `${serverHost}/users/getuser/${userId}`;
        const response = await axios.get(url);
        this.user = response.data.user[0];
        // Convert numeric to boolean for any flags if needed
        this.user.admin = this.user.admin === 1;
        this.user.trainer = this.user.trainer === 1;
        this.user.trainee = this.user.trainee === 1;
        this.user.active = this.user.active === 1;
        this.user.password = ''; // clear the password field
        //this.initialEmail = this.user.email;
      } catch (error) {
        console.error("TRACER Error fetching the users data from the backend: ", error);
      }
    },
    checkPasswordFormat() {
      if (this.user.password.length === 0) {
        this.passwordError = false;
        return;
      }
      this.passwordError = !utils.validatePassword(this.user.password);
    },
    checkEmailFormat() {
      this.emailError = !utils.validateEmail(this.user.email);
    },
    async checkEmailExistance() {
      this.emailExists = await utils.validateEmailExists(this.user.email);
    },
    async updateProfile() {
      if (this.emailError || this.passwordError) {
        return;
      }
      try {

        const userData = {
          title: this.user.title,
          fname: this.user.fname,
          lname: this.user.lname,
          org_id: this.user.org_id,
          rank: this.user.rank,
          admin: this.user.admin ? 1 : 0,
          trainer: this.user.trainer ? 1 : 0,
          trainee: this.user.trainee ? 1 : 0,
          email: this.user.email,
          address: this.user.address,
          city: this.user.city,
          state: this.user.state,
          postal_code: this.user.postal_code,
          country: this.user.country,
          phone: this.user.phone,
          mobile: this.user.mobile,
          active: this.user.active ? 1 : 0,
          password: this.user.password,
          notes: this.user.notes,
          updated_by: localStorage.getItem('userId'),
        };

        const serverHost = process.env.VUE_APP_SERVER_HOST;
        // const serverPort = process.env.VUE_APP_SERVER_PORT;
        const userId = this.user.user_id;
        const url = `${serverHost}/users/updateUser/${userId}`;

        const response = await axios.post(url, userData);

        if (response.status === 200) {
          this.profileEditSuccess = true;
        }
      } catch (error) {
        console.error("TRACER FRONTEND Error updating user: ", error);
        this.profileEditError = true;
      }
    },

  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  /* grid-template-columns: 1fr 5fr; */
  grid-template-areas:
    "r1 r1"
    "r2 r2"
    "r3 r3"
    "r4 r4"
    "r5 r5"
    "r6c1 r6c2"
    "r7c1 r7c2"
    "r8c1 r8c2"
    "r9c1 r9c2"
    "r10 r10"
    "r11c1 r11c2";
  grid-gap: 10px;

}

.form-title {
  grid-area: r1;
}

.names {
  grid-area: r2;
  display: flex;
  align-items: center;
  width: 100%;
}

.org-rank {
  grid-area: r3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.email {
  grid-area: r4;
}

.address {
  grid-area: r5;
}

.city {
  grid-area: r6c1;
}

.state {
  grid-area: r6c2;
}

.postal-code {
  grid-area: r7c1;
}

.country {
  grid-area: r7c2;
}

.phone {
  grid-area: r8c1;
}

.mobile {
  grid-area: r8c2;
}

.password {
  grid-area: r9c1;
}

.active {
  grid-area: r9c2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes {
  grid-area: r10;
}

.submit {
  grid-area: r11c2;
  display: flex;
  justify-content: flex-end;
}

/* .names select, */
.names input {
  display: inline-block;
  width: 100%;

}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .grid-container {
    display: grid;
    grid-template-areas:
      "r1"
      "r2"
      "r3"
      "r4"
      "r5"
      "r6c1"
      "r6c2"
      "r7c1"
      "r7c2"
      "r8c1"
      "r8c2"
      "r9c1"
      "r9c2"
      "r10"
      "r11c1"
      "r11c2";
    grid-gap: 1px;

  }

  .names {
    flex-wrap: wrap;
  }

  .names label,
  .names select,
  .names input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>