<template>
  <div class="create-team-container">
    <form @submit.prevent="createTeam">
      <div class="form-title">
        <h2>Create New Team</h2>
      </div>
      <div>
        <label for="teamName">Team Name:</label>
        <input type="text" class="inputs" :disabled="formDisabled" v-model="teamName" id="teamName" placeholder="Enter team name" />
      </div>
      <div>
        <label for="orgSelect">Organization:</label>
        <select class="selects" v-model="selectedOrgId" id="orgSelect" :disabled="formDisabled">
          <option value=0 disabled>Select organization</option>
          <option v-for="org in organizations" :key="org.ORG_ID" :value="org.ORG_ID">{{ org.NAME }}</option>
        </select>
      </div>
      <button type="submit" class="buttons" :disabled="formDisabled">Create Team</button>
      <p v-if="nameRequiredError" class="error-message">Please choose a team name.</p>
      <p v-if="orgRequiredError" class="error-message">Please select an organization.</p>
      <p v-if="teamCreatedSuccessfully" class="success-message"><strong>Team created successfully</strong></p>
      <p v-if="teamCreatedError" class="error-message-strong"><strong>Error creating team</strong></p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "CreateTeam",
  data() {
    return {
      teamName: '',
      selectedOrgId: 0,
      organizations: [],
      teamCreatedSuccessfully: false,
      teamCreatedError: false,
      formDisabled: false,
      nameRequiredError: false,
      orgRequiredError: false,
    };
  },
  created() {
    this.fetchOrganizations();
  },
  methods: {
    async fetchOrganizations() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_HOST}/orgs/getAllOrgs`);
        this.organizations = response.data;
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    },
    async createTeam() {
      this.nameRequiredError = !this.teamName;
      this.orgRequiredError = !this.selectedOrgId;

      if (this.nameRequiredError || this.orgRequiredError) {
        return;
      }

      this.formDisabled = true;
     
      try {
        const response = await axios.post(`${process.env.VUE_APP_SERVER_HOST}/teams/addTeam`, {
          org_id: this.selectedOrgId,
          team_name: this.teamName,
        });
        if (response.status === 201) {
          this.teamCreatedSuccessfully = true;
          
        } else {
          this.teamCreatedError = true;
        }
      } catch (error) {
        console.error('Error creating team:', error);
        this.teamCreatedError = true;
      } finally {
        this.formDisabled = false;
      }
    },
  },
};
</script>

<style scoped>
.create-team-container {
  max-width: 640px;
  min-width: 400px;
  padding: 20px;
  margin: 0 auto;
}
.form-title {
  margin-bottom: 20px;
}
.inputs, select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}
.buttons {
  width: 100%;
  padding: 10px;
}

</style>