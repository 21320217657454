<template>
  <div class="grid-container">
    <top-bar role="Trainer" paneltype="Trainer Panel" class="t-bar" />
    <div class="toolbar">
      <button class="toolbar-button buttons" @click="currentComponent = 'OrgAddTrainee'">Add Trainee</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'OrgAllTrainees'">Trainees</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'OrgCreateTeam'">Create Teams</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'OrgAllTeams'">Teams</button>     
      <button class="toolbar-button buttons" @click="currentComponent = 'OrgDashboards'">Dashboards</button>
      <button class="toolbar-button buttons" @click="currentComponent = 'OrgEditProfile'">Edit My Profile</button>
    </div>
    <div class="main">
      <component :is="currentComponent" @edit-team="editTeam" :team="selectedTeam" @team-updated="backToAllTeams" @cancel-team="backToAllTeams" @edit-trainee="editTrainee" :trainee="selectedTrainee" @show-trainees="backToAllTrainees"></component>
    </div>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import OrgAddTrainee from "../components/OrgAddTrainee.vue";
import OrgEditProfile from "../components/OrgEditProfile.vue";
import OrgAllTeams from "../components/OrgAllTeams.vue";
import OrgAllTrainees from "../components/OrgAllTrainees.vue";
import OrgCreateTeam from "../components/OrgCreateTeam.vue";
import OrgEditTeam from "../components/OrgEditTeam.vue";
import OrgEditTrainee from "../components/OrgEditTrainee.vue";


export default {
  name: "TrainerPanelView",
  components: {
    TopBar,
    OrgAddTrainee,
    OrgEditProfile,
    OrgAllTeams,
    OrgAllTrainees,
    OrgCreateTeam,
    OrgEditTeam,
    OrgEditTrainee,

  },
  data() {
    return {
      currentComponent : null,
      selectedTeam: null,
      selectedTrainee: null,
    };
  },
  methods: {
    editTeam(team) {
      this.selectedTeam = team;
      this.currentComponent = 'OrgEditTeam';
    },
    editTrainee(user) {
      this.selectedTrainee = user;
      this.currentComponent = 'OrgEditTrainee';
    },
    backToAllTeams() {
      this.currentComponent = 'OrgAllTeams';
    },
    backToAllTrainees() {
      this.currentComponent = 'OrgAllTrainees';
    },
  },
};
</script>

<style lang="css" scoped>
.grid-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 5fr;
  grid-gap: 10px;
  height: 100vh;
}

.t-bar {
  grid-column: span 2;
}

.toolbar {
  display: flex;
  flex-direction: column;
  /* padding-right: 20px; */
}

.toolbar-button {
  width: 100%;
  margin-top: 20px;
}

.content {
  padding: 20px;
  /* box-shadow: inset 2px 5px 10px #3d3d3d, inset -2px -5px 10px #949494;   */
}

@media (max-width: 640px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
