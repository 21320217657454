<template>
  <div>
    <h2>All Users</h2>
    <table>
      <thead>
        <tr>
          <th @click="sort('user_id')">User ID</th>
          <th @click="sort('fname')">First Name</th>
          <th @click="sort('lname')">Last Name</th>
          <th @click="sort('email')">Email</th>
          <th>Edit</th>
          <th>Delete</th>
          <th>Dashboard</th>
          <th>History</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in sortedUsers" :key="user.user_id">
          <td>{{ user.user_id }}</td>
          <td>{{ user.fname }}</td>
          <td>{{ user.lname }}</td>
          <td>{{ user.email }}</td>
          <td><button class="buttons-short" @click="editUser(user)">Edit</button></td>
          <td><button class="buttons-short" @click="deleteUser(user.user_id)">Delete</button></td>
          <td><button class="buttons-short">Dashboard</button></td>
          <td><button class="buttons-short">History</button></td>
        </tr>
      </tbody>
    </table>
    <div v-if="showEditUserForm">
      <h3>Edit User</h3>
      <input v-model="editUserData.fname" placeholder="First Name" />
      <input v-model="editUserData.lname" placeholder="Last Name" />
      <input v-model="editUserData.email" placeholder="Email" />
      <button @click="updateUser">Update</button>
      <button @click="showEditUserForm = false">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AllUsers',
  data() {
    return {
      users: [],
      showEditUserForm: false,
      editUserData: {
        user_id: null,
        fname: '',
        lname: '',
        email: '',
      },
      sortKey: '',
      sortAsc: true,
    };
  },
  computed: {
    sortedUsers() {
      return [...this.users].sort((a, b) => {
        let result = 0;
        if (a[this.sortKey] < b[this.sortKey]) result = -1;
        if (a[this.sortKey] > b[this.sortKey]) result = 1;
        return this.sortAsc ? result : -result;
      });
    },
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_HOST}/users/getall`);
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    editUser(user) {
      this.editUserData = { ...user };
      this.showEditUserForm = true;
    },
    async updateUser() {
      try {
        await axios.post(`${process.env.VUE_APP_SERVER_HOST}/users/updateUser/${this.editUserData.user_id}`, this.editUserData);
        const user = this.users.find(u => u.user_id === this.editUserData.user_id);
        Object.assign(user, this.editUserData);
        this.showEditUserForm = false;
      } catch (error) {
        console.error('Error updating user:', error);
      }
    },
    async deleteUser(userId) {
      try {
        await axios.delete(`${process.env.VUE_APP_SERVER_HOST}/users/deleteUser/${userId}`);
        this.users = this.users.filter(user => user.user_id !== userId);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
}
th {
  background-color: #656565;
  cursor: pointer;
}
button {
  margin: 5px;
}
</style>