<template>
  <div class="add-org-container">
    <form @submit.prevent="addOrganization">
      <div class="form-title">
        <h2>Add Organization</h2>
      </div>
      <div class="">
        <!-- <label for="orgName" class="labels">Organization Name:</label> -->
        <input type="text" class="inputs" id="orgName" v-model="orgName" placeholder="Enter organization name" required>
      </div>
      <button type="submit" style="margin-top: 30px;" class="buttons" :disabled="formDisabled">Submit</button>
      <p v-if="orgAddedSuccessfully" class="success-message"><strong>Organization added successfully</strong></p>
      <p v-if="orgAddedError" class="error-message-strong"><strong>Error adding organization</strong></p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "AddOrg",
  data() {
    return {
      orgName: '',
      orgAddedSuccessfully: false,
      orgAddedError: false,
      formDisabled: false,
    };
  },
  methods: {
    async addOrganization() {
      this.formDisabled = true;
      try {
        let url = process.env.VUE_APP_SERVER_HOST + '/orgs/addOrg';
        const response = await axios.put(url, { name: this.orgName });
        if (response.status === 201) {
          this.orgAddedSuccessfully = true;
        } else {
          this.orgAddedError = true;
        }
      } catch (error) {
        console.error('TRACER Error adding organization:', error);
        this.orgAddedError = true;
      } finally {
        this.formDisabled = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.add-org-container {
  max-width: 640px;
  min-width: 400px;
  height: 350px;
  padding: 20px;
  margin: 0 auto;
}
</style>