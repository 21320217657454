<template>
  <div><login-comp></login-comp></div>
</template>

<script>
import LoginComp from "@/components/LoginComp.vue";

export default {
  name: "LoginView",
  components: {
    LoginComp
  },
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="css" scoped></style>
