import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// Retrieve the token from local storage
const token = localStorage.getItem('token');
if (token) {
  // Set the token as the default authorization header
  axios.defaults.headers.common['Authorization'] = `${token}`;
}

// Create and mount the Vue application
createApp(App).use(store).use(router).mount('#app')


