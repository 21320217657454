<template>
  <div>
    <table>
      <thead>
        <tr>
          <th @click="sort('ORG_ID')">ORG ID</th>
          <th @click="sort('NAME')">NAME</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="org in sortedOrgs" :key="org.ORG_ID">
          <td>{{ org.ORG_ID }}</td>
          <td>{{ org.NAME }}</td>
          <td><button class="buttons-short" @click="editOrg(org)">Edit</button></td>
          <td><button class="buttons-short" @click="deleteOrg(org.ORG_ID)">Delete</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'AllOrgs',
  components: {

  },
  data() {
    return {
      orgs: [],
      sortKey: '',
      sortAsc: true,
    };
  },
  computed: {
    sortedOrgs() {
      return  [...this.orgs].sort((a, b) => {
        let result = 0;
        if (a[this.sortKey] < b[this.sortKey]) result = -1;
        if (a[this.sortKey] > b[this.sortKey]) result = 1;
        return this.sortAsc ? result : -result;
      });
    },
  },
  created() {
    this.fetchOrgs();
  },
  methods: {
    async fetchOrgs() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_HOST}/orgs/getAllOrgs`);
        this.orgs = response.data;
      } catch (error) {
        console.error('TRACER Error fetching organizations: ', error);
      }
    },
    sort(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
    editOrg(org) {
      this.$emit('edit-org', org);
    },
    async deleteOrg(orgId) {
      try {
        await axios.delete(`${process.env.VUE_APP_SERVER_HOST}/orgs/deleteOrg/${orgId}`);
        this.fetchOrgs();
      } catch (error) {
        console.error('TRACER Error deleting organization:', error);
      }
    },
  },

};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
}
th {
  cursor: pointer;
}
</style>