<template>
  <div class="passw-req-token-container">
    <h2>Request Password Change Token</h2>
    <p>Enter your email address below and we will send you a token to reset your password.</p>
    <input v-model="email" type="email" class="inputs" placeholder="Enter your email" />
    <button style="margin-top: 30px" class="buttons" @click="requestPasswordReset">Request Token</button>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "PasswordResetRequest",
  data() {
    return {
      email: '',
      message: '',
    };
  },
  methods: {
    async requestPasswordReset() {
      try {
        const response = await axios.post(process.env.VUE_APP_SERVER_HOST + ':' + process.env.VUE_APP_SERVER_PORT + '/users/requestPasswordReset', { email: this.email });
        this.message = response.data.message;
      } catch (error) {
        console.error("Error while requesting password reset, most probably the email address was not found here is the full error: ", error);
        this.message = "Error while requesting password reset. It could be that your email could not be found. Make sure you input the correct email address and try again.";
      }
    },
  },
};
</script>

<style lang="css" scoped>
.passw-req-token-container {
  border: 1px solid #656565;
  border-radius: 10px;
  max-width: 640px;
  min-width: 400px;
  height: 350px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}

</style>