<template>
  <div v-if="org">
    <form @submit.prevent="updateOrg">
      <div>
        <label for="orgName">Organization Name:</label>
        <input type="text" class="texts" v-model="organization.NAME" id="orgName" required>
      </div>
      <button class="buttons" style="width:fit-content" type="submit">Update</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditOrg',
  props: {
    org: Object,
  },
  data() {
    return {
      organization: this.org,
    };
  },
  methods: {
    async updateOrg() {
      try {
        await axios.put(`${process.env.VUE_APP_SERVER_HOST}/orgs/updateOrg/${this.org.ORG_ID}`, this.organization);
        this.$emit('org-updated');
      } catch (error) {
        console.error('TRACER Error updating organization: ', error);
      }
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 5px;
}
input {
  margin-bottom: 10px;
}
</style>