<template>
  <div class="passw-change-container">
    <h2>Change Password</h2>
    <p>Enter your email address, the token you received in your email, and your new password.</p>
    <input v-model="usersEmail" type="email" class="inputs" placeholder="Enter your email" />
    <input v-model="resetToken" type="text" class="inputs" placeholder="Copy the token from the email and paste it here" />
    <input v-model="password" type="password" class="inputs" placeholder="New password" />
    <input v-model="confirmPassword" type="password" class="inputs" placeholder="Confirm new password" />
    <button style="margin-top: 30px;" class="buttons" @click="changePassword">Change Password</button>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "PasswordChange",
  data() {
    return {
      usersEmail: '',
      resetToken: '',
      password: '',
      confirmPassword: '',
      message: '',
    };
  },
  methods: {
    async changePassword() {
      if (this.password !== this.confirmPassword) {
        this.message = "Passwords do not match.";
        return;
      }
      try {
        // Get the token from the database using the email address
        const tokenResponse = await axios.post(`${process.env.VUE_APP_SERVER_HOST}/users/getResetToken`, { email: this.usersEmail });
        const dbResetToken = tokenResponse.data.resetToken;

        // Compare the resetToken from the database with the user input
        if (this.resetToken !== dbResetToken) {
          this.message = "Reset token does not match. Please check your input and try again.";
          return;
        }

        // If tokens match, proceed with changing the password
        const changePasswordResponse = await axios.post(`${process.env.VUE_APP_SERVER_HOST}/users/changePassword`, { password: this.password, email: this.usersEmail });
        this.message = changePasswordResponse.data.message;
      } catch (error) {
        console.error("Error changing password:", error);
        this.message = "Failed to change password. Please try again.";
      }
    },
  },
};
</script>

<style lang="css" scoped>
.passw-change-container {
  border: 1px solid #656565;
  border-radius: 10px;
  max-width: 640px;
  min-width: 400px;
  height: 540px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 5px 5px 10px #3d3d3d, -5px -5px 10px #949494;
}
</style>